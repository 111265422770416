<template>
  <div class="box box_mm">
    <div class="box_main">
      <div class="cont_main_box">
        <div class="left_tool">
          <div class="left_name">选择部门</div>

          <div
            style="
              width: 100%;
              padding: 0 10px;
              margin-bottom: 10px;
              margin-top: 10px;
              display: flex;
            "
          >
            <el-input
              placeholder="输入关键字进行搜索"
              size="small"
              v-model="filterText"
              suffix-icon="el-icon-search"
            >
            </el-input>
          </div>
          <el-tree
            v-show="!filterText"
            class="filter-tree"
            :props="props"
            :load="loadNode"
            ref="tree"
            lazy
            highlight-current
            @node-click="change"
            node-key="id"
          >
          </el-tree>

          <div
            v-show="filterText"
            style="display: flex; flex-direction: column; padding: 0 10px"
          >
            <div
              style="
                margin-bottom: 5px;
                cursor: pointer;
                font-size: 14px;
                color: #666;
              "
              :class="searchid == item.id ? 'active' : ''"
              v-for="(item, index) in searchdata"
              :key="index"
              @click="change(item)"
            >
              {{ item.name }}
            </div>
            <!-- <el-checkbox v-for="(item,index) in searchdata" :key="index" v-model="item.ischeck" style="margin-bottom: 5px;" @change="setselect"></el-checkbox> -->
          </div>
        </div>
        <div class="right_table" :style="{ borderLeft: '1px solid #eee' }">
          <div class="search_box">
            <div class="search_item">
              <span style="width: 0.5rem">名称</span>
              <el-input
                v-model="searchinput"
                placeholder="请输入名称"
              ></el-input>
            </div>
            <div class="search_item">
              <el-button type="primary" @click="search">搜索</el-button>
            </div>
          </div>
          <div class="box_cont">
            <div class="btn_list">
              <el-button type="primary" @click="addnew">新增部门</el-button>
              <el-button type="warning" @click="editbmdata">编辑部门</el-button>
              <el-button type="primary" @click="addry">新增人员</el-button>
            </div>
            <div
              style="height: 68vh; padding-top: 10px; box-sizing: border-box"
            >
              <MyTable
                ref="accountref"
                :tableData="tableData"
                :tableHeader="tableHeader"
                @changswitch="changswitch"
                :showbm="true"
                :czwidth="190"
                @getdata="getlist"
                @changinput="changinput"
                @changxu="changxu"
              >
                <template slot="btns" slot-scope="{ scope }">
                  <el-button
                    @click="showedit(scope.row)"
                    type="primary"
                    size="mini"
                    >编辑</el-button
                  >
                  <el-button
                    @click="deleterow(scope.row)"
                    type="danger"
                    size="mini"
                    >删除</el-button
                  >
                </template>
              </MyTable>
            </div>
          </div>
        </div>
      </div>
    </div>

    <detail ref="mydetail"></detail>
    <editebm ref="editebm"></editebm>
    <edituser ref="myadd"></edituser>
  </div>
</template>

<script>
import edituser from "./components/add_wy.vue";
import detail from "./components/detail.vue";
import editebm from "./components/editebm.vue";
export default {
  components: { detail, edituser, editebm },
  data() {
    return {
      openflag: true,
      filterText: "",
      searchid: "",
      props: {
        label: "name",
        children: "zones",
        isLeaf: "leaf",
      },
      dialogTitle: "新增小组",
      isShowDialog: false,
      searchinput: "",
      tableHeader: [
        {
          prop: "xu",
          name: "序",
          width: "150",
          type: "input-paixu",
        },
        {
          name: "名称",
          prop: "name",
          width: "",
        },
        {
          name: "登录名",
          prop: "username",
          width: "",
        },
        {
          name: "联系电话",
          prop: "phone",
        },
        {
          name: "性别",
          prop: "sex_name",
        },
      ],
      //add新增updata修改
      dialogType: "add",
      tableData: [],
      formItem: [
        {
          label: "名称",
          keys: "name",
          required: true,
          message: "名称不能为空",
          trigger: "blur",
        },
      ],
      searchdata: [],
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    changxu(obj) {
      let yxu = obj.row.xu;
      let xxu = "";
      if (obj.type == 1) {
        xxu = obj.row.xu - 1;
      } else {
        xxu = obj.row.xu + 1;
      }
      this.$post({
        url: "/api/user/paixu",
        params: {
          id2: obj.row.id2,
          yxu: yxu,
          xxu: xxu,
        },
      }).then((res) => {
        this.$message.success("操作成功");
        this.getlist();
      });
    },
    changinput(row) {
      this.$post({
        url: "/api/user/paixu",
        params: {
          id2: row.id2,
          xxu: row.xu,
        },
      }).then((res) => {
        this.$message.success("操作成功");
        this.getlist();
      });
    },
    closeChildDialog() {
      this.isShowDialog = false;
    },
    //子组件点击确定事件
    sureDialog(formData) {
      this.isShowDialog = false;
      console.log(formData);
    },
    //子组件点击取消dialog
    cancelDialog(data) {
      this.isShowDialog = data;
    },
    //新增人员
    addry() {
      this.$refs.myadd.dialogVisible = true;
    },
    //新增
    addnew() {
      this.$refs.mydetail.dialogVisible = true;
      this.$refs.mydetail.isedite = true;
    },
    editbmdata() {
      this.$refs.editebm.dialogVisible = true;
      this.$refs.editebm.loadNode();
    },
    //搜索
    search() {
      this.$refs.accountref.inputpage = 1;
      this.getlist();
    },
    getlist() {
      this.$post({
        url: "/api/user/index",
        params: {
          name: this.searchinput,
          order_by: "",
          sort: "",
          organizational_id: this.searchid,
          p: this.$refs.accountref.inputpage,
          size: this.$refs.accountref.pagesize,
        },
      }).then((res) => {
        res.list.forEach((item, index) => {
          item.index =
            index +
            1 +
            (this.$refs.accountref.inputpage - 1) *
              this.$refs.accountref.pagesize;
          item.sex_name = item.sex == 1 ? "男" : "女";
          // this.optionmz.forEach((item2,index2)=>{
          // 	if(item.nation_id == item2.id){
          // 		item.nation_name = item2.name
          // 	}
          // })
        });
        this.tableData = res.list;
        this.$refs.accountref.total = res.count;
      });
    },
    // 修改使用状态需要调用接口
    changswitch(row) {
      let baseinfo = JSON.parse(JSON.stringify(row));
      baseinfo.password = "";
      delete baseinfo.createtime;
      delete baseinfo.updatetime;
      delete baseinfo.ROW_NUMBER;
      delete baseinfo.avatarurl;
      // 修改使用状态需要调用接口
      // this.$post({
      // 	url: '/admin/admin/edit/id/'+baseinfo.id,
      // 	params: baseinfo
      // }).then((res) => {
      // 	this.$message.success('操作成功')
      // 	this.gettable()
      // })
    },
    // 编辑按钮
    showedit(row) {
      this.$post({
        url: "/api/user/details",
        params: {
          id: row.id,
        },
      }).then((res) => {
        res.organizational_name = res.roles_name;
        this.$refs.myadd.userimg = res.photo;
        this.$refs.myadd.ruleForm = res;
        (this.$refs.myadd.ruleForm.useradmin = res.useradmin
          ? res.useradmin.split(",").map((item) => {
              return Number(item);
            })
          : []),
          (this.$refs.myadd.ruleForm.organizational_name = res.roles_name);
        this.$refs.myadd.ruleForm.organizational_id = res.roles;
        this.$refs.myadd.ruleForm.sex = Number(res.sex);
        // this.$refs.myadd.sethuixian()
        this.$refs.myadd.dialogVisible = true;
      });
    },
    deleterow(row) {
      this.$confirm("确认删除吗？")
        .then((_) => {
          this.$post({
            url: "/api/user/del",
            params: {
              ids: row.id,
            },
          }).then((res) => {
            this.$message.success("删除成功");
            this.getlist();
          });
        })
        .catch((_) => {});
    },
    change(a, b, c) {
      this.searchid = a.id;
      this.$refs.accountref.inputpage = 1;
      this.$refs.accountref.pagesize = 15;
      this.$post({
        url: "/api/user/index",
        params: {
          order_by: "",
          sort: "",
          organizational_id: a.id,
          p: this.$refs.accountref.inputpage,
          size: this.$refs.accountref.pagesize,
        },
      }).then((res) => {
        res.list.forEach((item, index) => {
          item.index =
            index +
            1 +
            (this.$refs.accountref.inputpage - 1) *
              this.$refs.accountref.pagesize;
          item.sex_name = item.sex == 1 ? "男" : "女";
        });
        this.tableData = res.list;
        this.$refs.accountref.total = res.count;
      });
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        this.$post({
          url: "/api/organizational/quan",
          params: {
            order_by: "",
            sort: "",
            type: 1,
            pid: 0,
            p: 1,
            size: 9999,
          },
        }).then((res) => {
          this.searchid = res.list[0].id;
          this.getlist();
          let list = res.list.map((item) => {
            return { name: item.name, id: item.id, isLeaf: !item.xia };
          });
          setTimeout(() => {
            this.$refs.tree.setCurrentKey(this.searchid);
          }, 10);

          return resolve(list);
        });
      } else {
        this.$post({
          url: "/api/organizational/index",
          params: {
            order_by: "",
            sort: "",
            type: 1,
            pid: node.data.id,
            p: 1,
            size: 9999,
          },
        }).then((res) => {
          let list = res.list.map((item) => {
            return { name: item.name, id: item.id, isLeaf: !item.xia };
          });
          return resolve(list);
        });
      }
    },
  },
  watch: {
    filterText(val) {
      if (val) {
        this.$post({
          url: "/api/organizational/index",
          params: {
            order_by: "",
            sort: "",
            type: 2,
            name: val,
            p: 1,
            size: 9999,
          },
        }).then((res) => {
          let list = res.data.list.map((item) => {
            return {
              name: item.name,
              id: item.id,
              // disabled:item.xia,
              isLeaf: !item.xia,
              ischeck: false,
            };
          });
          this.searchdata = list;
        });
      } else {
        if (this.selectdata.length > 0) {
          this.$refs.tree.setCheckedNodes(this.selectdata);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box_main {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
  overflow: hidden;
}

.search_box {
  display: flex;
  align-items: center;
  font-size: 0.16rem;

  .search_item {
    display: flex;
    align-items: center;
    margin-right: 0.8rem;
  }
}

.box_cont {
  margin-top: 20px;
}

.btn_list {
  display: flex;
  align-items: center;
}

::v-deep td {
  text-align: center !important;
}

::v-deep .el-table th.el-table__cell > .cell {
  text-align: center !important;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  background-color: rgb(242, 242, 242);
  color: #777;
}

::v-deep .el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
}

::v-deep .el-table td.el-table__cell {
}

::v-deep .el-table {
  margin-top: 10px;
}

::v-deep .el-table__fixed {
}

::v-deep .el-table::before {
  background-color: #fff !important;
}

::v-deep .el-table__fixed::before {
  background-color: #fff !important;
}

::v-deep .el-dialog__header {
  display: flex !important;
}

::v-deep .el-dialog__footer span {
  display: flex !important;
  justify-content: space-around;
  width: 100%;
}

::v-deep .el-form-item__content {
  display: flex;
  flex-direction: column;
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

::v-deep .el-input--small .el-input__icon {
  color: #333;
  font-weight: bold;
}
.search_mid_item {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 8px;
  .searchname {
    font-size: 14px;
    font-weight: bold;
    width: 1.4rem;
    text-align: right;
  }
}
.search_mid_line {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 5%;
  .search_mid_item:nth-child(1) {
    .searchname {
      width: 1.6rem;
    }
  }
}
.search_midbox {
  flex: 1;
  height: 40px;
  overflow: hidden;
}
.openserch {
  height: auto;
}
.cont_main_box {
  display: flex;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.left_tool {
  width: 200px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transition: all 0.5;
}
.closeleft {
  width: 1px;
}
.left_btn {
  position: absolute;
  right: -14px;
  width: 28px;
  height: 28px;
  background-color: rgb(253, 237, 237);
  top: 30%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  padding-left: 2px;
  color: #666;
  cursor: pointer;
  z-index: 5;
}
.left_name {
  padding: 10px 16px;
  font-size: 16px;
  height: 53px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.right_table {
  width: calc(100% - 200px);
  padding: 10px;
  padding-right: 0;
  position: relative;
}
.right_btn {
  position: absolute;
  left: -14px;
  width: 28px;
  height: 28px;
  background-color: rgb(253, 237, 237);
  top: 30%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  padding-left: 2px;
  color: #666;
  cursor: pointer;
  z-index: 9;
  justify-content: flex-end;
}
.left_tool,
.right_table {
  height: 100%;
}
::v-deep .el-table td.el-table__cell {
  border-right: 1px solid #eee;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  border-right: 1px solid #eee;
}

::v-deep .el-table td.el-table__cell div p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.tb_box {
  margin-top: 10px;
  background-color: #fff;
  height: 86% !important;
}

.table_search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.tc_search {
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 60px;
  z-index: 5;
}

.tc_searchbox {
  width: 100%;
  padding: 10px 10%;
  background-color: #fff;
  font-size: 16px;
  border-top: 1px solid #ec4a4a;
}
.search_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sitem_inner {
  flex: 1;
}
.inpust {
  width: 4.5rem;
}
::v-deep .btn_searchbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
::v-deep .el-input-group__append {
  background-color: #ec4a4a !important;
  padding: 0;
}
::v-deep .el-input-group--append .el-input__inner {
  border: none;
}
::v-deep .el-input-group__append {
  background: #fff;
  background-color: #fff !important;
  border: none;
  padding: 0;
}
::v-deep .el-input-group__append i {
  padding: 0px 10px;
  color: #333 !important;
}
::v-deep .el-input-group__append i:hover {
  color: #d00 !important;
}
.table_search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
}
.searchtbn:after {
  content: "";
  position: absolute;
  width: 98%;
  height: 1px;
  background-color: #eee;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.searchtbn {
  padding-top: 0;
}

.tc_search {
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 60px;
  z-index: 5;
}

.tc_searchbox {
  width: 100%;
  padding: 10px 10%;
  background-color: #fff;
  font-size: 16px;
  border-top: 1px solid #ec4a4a;
}
.search_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sitem_inner {
  flex: 1;
}
.inpust {
  width: 4.5rem;
}
::v-deep .btn_searchbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
::v-deep .el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tc_search {
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 52px;
  z-index: 5;
}

.tc_searchbox {
  width: 100%;
  padding: 10px 10%;
  background-color: #fff;
  font-size: 16px;
  border-top: 1px solid #ec4a4a;
}
.search_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sitem_inner {
  flex: 1;
}
.inpust {
  width: 4.5rem;
}
::v-deep .tc_searchbox .el-form-item__label {
  color: #333;
}
.active {
  background-color: rgb(253, 241, 241);
}
::v-deep .mytableinput .el-input__inner {
  border: none !important;
}
::v-deep .mytableinput .el-input__inner:focus {
  border: 1px solid #dcdfe6 !important;
}
</style>
